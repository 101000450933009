@tailwind base;
@tailwind components;
@tailwind utilities;

body {   
  @apply
  font-sofia; }





