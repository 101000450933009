@keyframes scroll {
    0% {
      transform: translateX(0%);
    }
    100% {
      transform: translateX(-100%);
    }
  }
  
  .scroll-container {
    display: flex;
    overflow: hidden;
    white-space: nowrap;
  }
  
  .scroll-content {
    display: inline-flex;
    animation: scroll 40s linear infinite;
  }
  
  .scroll-content img {
    margin: 0 1rem;
  }


  