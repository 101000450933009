/* @keyframes slide-in {
    0% {
      transform: translateX(-100%);
      opacity: 0;
    }
    100% {
      transform: translateX(0);
      opacity: 1;
    }
  }
  
  .slide-in {
    animation: slide-in 1s ease-in-out forwards;
  }
   */

/* Add this to your global stylesheet or CSS module */
.gradient-border {
  border-image: linear-gradient(to right, #FFDA57, #FFDA57 50%, #5956E8 50%, #5956E8) 1;
}


@keyframes slideInFromLeft{
  0%{
    transform: translateX(-100%);
    opacity: 0;
  }
  100%{
    transform: translateX(0);
    opacity: 1;
  }
}

.slide-in{
  /* opacity: 0; */
  animation: slideInFromLeft 1s ease-out forwards;
}


   @keyframes fadeInUp {
    0% {
      opacity: 0;
      transform: translateY(20px);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  .animate-line {
    opacity: 0; /* Ensures the text is initially hidden */
    animation-fill-mode: forwards;
    animation-timing-function: ease-out;
    animation-duration: 1s; /* Animation duration for each line */
    /* display: inline-block; */
  }
  
  .fade-in-up-1 {
    animation-name: fadeInUp;
    animation-delay: 0.2s;
  }
  
  .fade-in-up-2 {
    animation-name: fadeInUp;
    animation-delay: 0.4s;
  }
  
  .fade-in-up-3 {
    animation-name: fadeInUp;
    animation-delay: 0.6s;
  }
  
  .fade-in-up-4 {
    animation-name: fadeInUp;
    animation-delay: 0.8s;
  }


  @keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.fade-in-up {
  animation: fadeInUp 0.6s ease-out;
}



.horizontal-lines {
  display: inline-block;
  position: relative;
}

.horizontal-lines::before,
.horizontal-lines::after {
  content: '';
  display: block;
  position: absolute;
  width: 100%;
  height: 2px;
  background: white;
}

.horizontal-lines::before {
  top: 0;
}

.horizontal-lines::after {
  bottom: 0;
}


/* Slide in animations */
@keyframes slideInLeft {
  from {
      transform: translateX(-100%);
      opacity: 0;
  }
  to {
      transform: translateX(0);
      opacity: 1;
  }
}

@keyframes slideInRight {
  from {
      transform: translateX(100%);
      opacity: 0;
  }
  to {
      transform: translateX(0);
      opacity: 1;
  }
}

.slide-in-left {
  animation: slideInLeft 1s ease-out forwards;
}

.slide-in-right {
  animation: slideInRight 1s ease-out forwards;
}





